import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.675) translateY(-12px)',
};

export const theme = extendTheme({
  colors: {
    ...chakraTheme.colors,
    primary: '#091023',
    primaryBg: '#091023',
    primaryAlpha: {
      50: 'rgb(9, 16, 35, 0.05)',
      100: 'rgb(9, 16, 35, 0.1)',
      200: 'rgb(9, 16, 35, 0.2)',
      300: 'rgb(9, 16, 35, 0.3)',
      400: 'rgb(9, 16, 35, 0.4)',
      500: 'rgb(9, 16, 35, 0.5)',
      600: 'rgb(9, 16, 35, 0.6)',
      700: 'rgb(9, 16, 35, 0.7)',
      800: 'rgb(9, 16, 35, 0.8)',
      900: 'rgb(9, 16, 35, 0.9)',
    },
    secondaryBg: '#F5F5F6',
    green: {
      50: '#F0FFF4',
      100: '#C6F6D5',
      200: '#9AE6B4',
      300: '#68D391',
      400: '#49bf7a',
      500: '#00BA61',
      600: '#2F855A',
      700: '#276749',
      800: '#22543D',
      900: '#1C4532',
    },
    red: '#FF0000',
    whiteAlpha: {
      50: 'rgb(255,255,255, 0.05)',
      100: 'rgb(255,255,255, 0.1)',
      200: 'rgb(255,255,255, 0.2)',
      300: 'rgb(255,255,255, 0.3)',
      400: 'rgb(255,255,255, 0.4)',
      500: 'rgb(255,255,255, 0.5)',
      600: 'rgb(255,255,255, 0.6)',
      700: 'rgb(255,255,255, 0.7)',
      800: 'rgb(255,255,255, 0.8)',
      900: 'rgb(255,255,255, 0.9)',
    },
    blackAlpha: {
      50: 'rgb(0,0,0, 0.05)',
      100: 'rgb(0,0,0, 0.1)',
      200: 'rgb(0,0,0, 0.2)',
      300: 'rgb(0,0,0, 0.3)',
      400: 'rgb(0,0,0, 0.4)',
      500: 'rgb(0,0,0, 0.5)',
      600: 'rgb(0,0,0, 0.6)',
      700: 'rgb(0,0,0, 0.7)',
      800: 'rgb(0,0,0, 0.8)',
      900: 'rgb(0,0,0, 0.9)',
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              pointerEvents: 'none',
              mx: '15px',
              px: 1,
              my: '16px',
              transformOrigin: 'left top',
            },
            input: {
              height: '56px',
              paddingTop: '24px',
              paddingBottom: '8px',
            },
            textarea: {
              height: '171px',
              paddingTop: '24px',
              paddingBottom: '8px',
            },
          },
        },
      },
    },
  },
  fonts: {
    ...chakraTheme.fonts,
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  fontSizes: {
    'xx-small': '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.87rem',
    '4xl': '2.25rem',
    '5xl': '2.5rem',
    '6xl': '3rem',
    '7xl': '3.75rem',
  },
});
