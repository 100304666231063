/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { withEmotionCache } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
} from '@remix-run/react';
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction,
} from '@vercel/remix';
import { json } from '@vercel/remix';
import { withSentry } from '@sentry/remix';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { hotjar } from 'react-hotjar';

import { gtag } from '~/utils/analytics/gtag';

import styles from '~/assets/global.css';

import { ClientStyleContext, ServerStyleContext } from './context';
import { theme } from './theme';
import Fonts from './fonts';
import { config } from 'config';
import { features } from './features';
import { FbPixelCode } from '~/components/scripts/FbPixelCode';

/**
 * @description
 * If you would like to include the development env values in your browser bundle AKA
 * set some global values on the window object, take a look at these docs here:
 * https://remix.run/guides/envvars#server-environment-variables
 */
// export async function loader() {
//   return json({
//     ENV: {
//       APP_ENV: process.env.NODE_ENV,
//     },
//   });
// }

// Load the GA tracking id from the .env
export const loader: LoaderFunction = () => {
  return json({ gaTrackingId: 'G-5PRHHFRCZ8' });
};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'Lunar Art',
  viewport: 'width=device-width,initial-scale=1',
});

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: styles,
    },
  ];
};

interface DocumentProps {
  children: React.ReactNode;
}

const GoogleAnalytics = ({ gaTrackingId }: { gaTrackingId: string }) => (
  <>
    {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
      <>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
        />
        <script
          async
          id="gtag-init"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaTrackingId}', {
            page_path: window.location.pathname,
          });
        `,
          }}
        />
      </>
    )}
  </>
);

const Document = withEmotionCache(
  ({ children }: DocumentProps, emotionCache) => {
    const serverStyleData = useContext(ServerStyleContext);
    const clientStyleData = useContext(ClientStyleContext);

    // Google Analytics
    const location = useLocation();
    const { gaTrackingId } = useLoaderData<typeof loader>();

    useEffect(() => {
      if (features.hotjar) {
        hotjar.initialize(config.HOTJAR_HJID, config.HOTJAR_HJSV);
      }
    }, []);

    useEffect(() => {
      if (gaTrackingId?.length) {
        gtag.pageview(location.pathname, gaTrackingId);
      }
    }, [location, gaTrackingId]);

    // Only executed on client
    useEffect(() => {
      // re-link sheet container
      emotionCache.sheet.container = document.head;
      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach(tag => {
        (emotionCache.sheet as any)._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData?.reset();
    }, []);

    return (
      <html lang="en">
        <head>
          <FbPixelCode />
          <Meta />
          <Links />
          {serverStyleData?.map(({ key, ids, css }) => (
            <style
              key={key}
              data-emotion={`${key} ${ids.join(' ')}`}
              dangerouslySetInnerHTML={{ __html: css }}
            />
          ))}
        </head>
        <GoogleAnalytics gaTrackingId={gaTrackingId} />
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </body>
      </html>
    );
  },
);

function App() {
  const gtmParams = { id: config.GTM_ID };

  return (
    <GTMProvider state={gtmParams}>
      <Document>
        <ChakraProvider theme={theme}>
          <Fonts />
          <Outlet />
        </ChakraProvider>
      </Document>
    </GTMProvider>
  );
}

export default withSentry(App);
